








import {
defineComponent,
onMounted,
ref,
} from '@nuxtjs/composition-api';
import { useProduct } from '~/composables';
import ProductsCarousel from '~/modules/catalog/product/components/ProductsCarousel.vue';
import useRelatedProducts from '~/modules/catalog/product/composables/useRelatedProducts';
import { usePageStore } from '~/stores/page';

export default defineComponent({
  name: 'RelatedProducts',
  components: {
    ProductsCarousel,
  },
  setup() {
    const { routeData } = usePageStore();
    const {
      search,
      loading,
    } = useRelatedProducts();

    const { getProductList, getProductDetails } = useProduct();

    const products = ref<any[]>([]);

    onMounted(async () => {
      const baseSearchQuery = {
        filter: {
          sku: {
            eq: routeData.sku,
          },
        },
      };

      const searchResult = await search(baseSearchQuery);
      products.value = searchResult || [];

      if (products.value.length === 0) {
        const product = await getProductDetails({
          filter: {
            sku: {
              eq: routeData.sku,
            },
          },
        });
        const categoryUids = product.items[0].categories.map((category: any) => category.uid);

        const productList = await getProductList({
          filter: {
            category_uid: {
              in: categoryUids,
            },
          },
          pageSize: 100,
        });

        products.value = productList.items.sort(() => Math.random() - 0.5).slice(0, 15) || [];
      }
    });

    return {
      products,
      loading,
    };
  },
});
